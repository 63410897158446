.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  .button:hover,
  .button:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
.section--one .subcontent.subcontent--1 {
  width: calc(50% - 20px);
}
.section--one .subcontent.subcontent--2 {
  float: right;
  width: calc((50% - 20px) + var(--containerIndent));
}
.area--one.area1 {
  margin-top: calc(var(--spaceTotal) * -1);
}
.area--two {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.area--two .unit {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}
.area--two .unitOne--1-2 {
  width: calc(50% - 40px);
}
.area--two .unitOne--1-2WithIndent {
  width: calc(50% - 40px);
  padding-right: var(--containerIndent);
}
.area--two .unitTwo .unit__background {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(50% - 20px);
}
.area--two .unitTwo .unit__background * {
  height: 100%;
}
.area--two .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitTwo--imageLeft .unit__background {
  left: 0;
}
.area--two .unitTwo--imageLeft .unit__content {
  padding-left: calc(50% + 20px);
  padding-right: var(--containerIndent);
}
.area--two .unitTwo--imageRight .unit__background {
  right: 0;
}
.area--two .unitTwo--imageRight .unit__content {
  padding-right: calc((50% + 20px) + var(--containerIndent));
}
.area--two .formOne--1-2 {
  width: calc(50% - 40px);
}
/*# sourceMappingURL=./screen-medium.css.map */